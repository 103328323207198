<template>
    <path d="M0 12.336H43.5992L33.5683 2.35308L35.9327 0L50 14L35.9327 28L33.5683 25.6469L43.5994 15.6638H0V12.336Z" fill="#383838"/>
</template>

<script>
    export default {
        name: "ArrowRight"
    }
</script>

<style scoped>

</style>