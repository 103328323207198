import { render, staticRenderFns } from "./QualityAssurance.vue?vue&type=template&id=bdf92f66&scoped=true"
import script from "./QualityAssurance.vue?vue&type=script&lang=js"
export * from "./QualityAssurance.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdf92f66",
  null
  
)

export default component.exports