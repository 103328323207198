<template>
    <g>
        <circle cx="13" cy="13" r="12.5" stroke="#383838" fill="#383838"/>
        <circle class="hover" cx="13" cy="13" r="12.5" stroke="#383838" fill="#fff"/>
        <path d="M19.5378 14.7284C19.3868 14.5854 19.2358 14.4424 19.087 14.2971C18.5822 13.8046 18.5501 13.5191 18.9566 12.9519C19.1924 12.6214 19.4378 12.2977 19.6832 11.974C19.8984 11.6901 20.1137 11.4062 20.3225 11.1176C20.3372 11.0972 20.352 11.0768 20.3667 11.0564C20.7693 10.4998 21.1736 9.94068 21.3854 9.2742C21.5248 8.83446 21.4013 8.6405 20.9505 8.56341C20.8725 8.5499 20.7924 8.54916 20.7136 8.54916L18.0389 8.54597C17.7094 8.54106 17.5277 8.68469 17.4108 8.97662C17.2537 9.37143 17.0919 9.76549 16.9043 10.1463C16.4793 11.0098 16.0047 11.8441 15.3386 12.551C15.3292 12.5609 15.3197 12.5711 15.3101 12.5813C15.1706 12.7305 15.014 12.8981 14.7842 12.8223C14.4778 12.7106 14.3869 12.2048 14.3921 12.0341L14.3896 8.94741C14.3302 8.50644 14.232 8.31002 13.7942 8.22409L11.0166 8.22458C10.6456 8.22458 10.46 8.36797 10.2619 8.59974C10.1477 8.7338 10.1133 8.82071 10.3481 8.86515C10.8089 8.95256 11.0689 9.25112 11.1377 9.71344C11.2482 10.4522 11.2405 11.1937 11.1767 11.9354C11.1581 12.152 11.121 12.368 11.035 12.5701C10.9007 12.8881 10.6837 12.9526 10.3989 12.7572C10.1408 12.5802 9.95965 12.3302 9.78213 12.0796C9.11603 11.1382 8.58447 10.1232 8.15087 9.05642C8.02541 8.74829 7.80935 8.56144 7.4828 8.55628C6.68042 8.54352 5.87805 8.54155 5.07568 8.55702C4.59273 8.56611 4.44885 8.80034 4.64552 9.23811C5.51934 11.1819 6.49186 13.0715 7.76171 14.7931C8.41383 15.6762 9.16218 16.4563 10.1291 17.009C11.2251 17.6355 12.4043 17.8241 13.6472 17.7659C14.2288 17.7386 14.4036 17.5872 14.4304 17.0075C14.4491 16.611 14.4937 16.2162 14.6894 15.8599C14.8814 15.5098 15.1726 15.443 15.5078 15.661C15.6757 15.7698 15.8166 15.908 15.9504 16.0548C16.0588 16.1732 16.1659 16.2929 16.273 16.4126C16.4895 16.6545 16.706 16.8966 16.9335 17.128C17.3595 17.5621 17.8648 17.8182 18.4997 17.7657L20.9582 17.7661C21.3539 17.7399 21.5597 17.255 21.3318 16.8128C21.1723 16.5027 20.9626 16.227 20.7401 15.9603C20.3716 15.5178 19.9547 15.1231 19.5378 14.7284Z" fill="#fff"/>
    </g>
</template>

<script>
    export default {
        name: "VkIcon"
    }
</script>

<style scoped>

</style>