<template>
    <g>
        <path d="M20.7987 1.9461C21.4381 2.55301 21.9495 3.27508 22.3041 4.07191C22.6719 4.90117 22.8598 5.78179 22.8571 6.68808C22.8571 7.54302 22.6772 8.43391 22.32 9.3402C22.0237 10.0976 21.5924 10.8832 21.0447 11.6765C20.1769 12.932 18.9837 14.2414 17.502 15.5687C15.0441 17.769 12.6152 19.2863 12.512 19.3505L11.4034 20L10.3451 19.3505C10.2419 19.2889 7.81042 17.769 5.35511 15.5687C3.87346 14.2414 2.6802 12.932 1.81238 11.6765C1.2647 10.8832 0.836075 10.0976 0.537099 9.3402C0.179915 8.43391 0 7.54302 0 6.68808C0 5.78179 0.187852 4.90117 0.555619 4.07191C0.909305 3.27457 1.42079 2.55232 2.06108 1.9461C2.70401 1.33506 3.45278 0.85496 4.28356 0.521199C5.14345 0.174601 6.0536 1.80459e-05 6.99286 1.80459e-05C8.29725 1.80459e-05 9.56988 0.346616 10.6758 1.0013C10.9404 1.15791 11.1918 1.32993 11.4299 1.51735C11.668 1.32993 11.9194 1.15791 12.1839 1.0013C13.2899 0.346616 14.5625 1.80459e-05 15.8669 1.80459e-05C16.7967 -0.0020585 17.7176 0.175097 18.5762 0.521199C19.4042 0.853653 20.1587 1.33739 20.7987 1.9461Z" fill="#383838"/>
    </g>
</template>

<script>
    export default {
        name: "FavouriteIcon"
    }
</script>

<style scoped>

</style>