import { render, staticRenderFns } from "./PopupCoolLightBox.vue?vue&type=template&id=8412d31e&scoped=true"
import script from "./PopupCoolLightBox.vue?vue&type=script&lang=js"
export * from "./PopupCoolLightBox.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8412d31e",
  null
  
)

export default component.exports