<template>
    <path d="M14 0L0 0L7 12L14 0Z" fill="#383838"/>
</template>

<script>
    export default {
        name: "TriangleDown"
    }
</script>

<style scoped>

</style>