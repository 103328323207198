<template>
    <g>
        <circle cx="13" cy="13" r="12.5" stroke="#383838" fill="#383838"/>
        <circle class="hover" cx="13" cy="13" r="12.5" stroke="#383838" fill="#fff"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1895 16.6194C20.4982 15.4459 20.4982 13.0123 20.4982 13.0123C20.4982 13.0123 20.5107 10.5665 20.1895 9.39301C20.0165 8.75067 19.51 8.24402 18.8677 8.07117C17.6942 7.75 13.0001 7.75 13.0001 7.75C13.0001 7.75 8.30597 7.75 7.13245 8.0589C6.50238 8.23175 5.98346 8.75067 5.81061 9.39301C5.50171 10.5665 5.50171 13 5.50171 13C5.50171 13 5.50171 15.4459 5.81061 16.607C5.98364 17.2493 6.49011 17.756 7.13245 17.9288C8.31824 18.25 13.0001 18.25 13.0001 18.25C13.0001 18.25 17.6942 18.25 18.8677 17.9411C19.51 17.7682 20.0165 17.2618 20.1895 16.6194ZM15.4088 13L11.5054 15.2482V10.7518L15.4088 13Z" fill="#fff"/>
    </g>
</template>

<script>
    export default {
        name: "YoutubeIcon"
    }
</script>

<style scoped>

</style>