import { render, staticRenderFns } from "./MainPage.vue?vue&type=template&id=aa9d0e02&scoped=true"
import script from "./MainPage.vue?vue&type=script&lang=js"
export * from "./MainPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa9d0e02",
  null
  
)

export default component.exports