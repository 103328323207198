<template>
    <path d="M0 0.662C0.00146404 0.486919 0.0855202 0.319345 0.23401 0.195478C0.3825 0.071612 0.583504 0.00139643 0.7936 0H15.2064C15.6448 0 16 0.296667 16 0.662V11.338C15.9985 11.5131 15.9145 11.6807 15.766 11.8045C15.6175 11.9284 15.4165 11.9986 15.2064 12H0.7936C0.583051 11.9998 0.381197 11.93 0.232392 11.8059C0.0835856 11.6817 -1.06764e-07 11.5135 0 11.338V0.662ZM6.8976 3.61C6.84945 3.58323 6.79349 3.56785 6.73568 3.56548C6.67788 3.56312 6.62038 3.57386 6.56932 3.59657C6.51826 3.61929 6.47554 3.65311 6.44573 3.69445C6.41591 3.73579 6.40011 3.7831 6.4 3.83133V8.16867C6.40011 8.2169 6.41591 8.26421 6.44573 8.30555C6.47554 8.34689 6.51826 8.38071 6.56932 8.40343C6.62038 8.42614 6.67788 8.43688 6.73568 8.43452C6.79349 8.43216 6.84945 8.41677 6.8976 8.39L10.8008 6.222C10.8447 6.19766 10.8807 6.16465 10.9056 6.12592C10.9305 6.08719 10.9435 6.04394 10.9435 6C10.9435 5.95606 10.9305 5.91281 10.9056 5.87408C10.8807 5.83535 10.8447 5.80234 10.8008 5.778L6.8976 3.61Z" fill="white"/>
</template>

<script>
    export default {
        name: "VideoIcon"
    }
</script>

<style scoped>

</style>