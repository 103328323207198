<template>
    <path d="M50 12.336H6.40079L16.4317 2.35308L14.0673 0L0 14L14.0673 28L16.4317 25.6469L6.40058 15.6638H50V12.336Z" fill="#383838"/>
</template>

<script>
    export default {
        name: "ArrowLeft"
    }
</script>

<style scoped>

</style>