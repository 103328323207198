<template>
    <path d="M18.0017 16.0017L12.8477 10.8477C13.8005 9.39959 14.1821 7.64945 13.9187 5.93613C13.6552 4.22281 12.7654 2.66819 11.4216 1.57324C10.0777 0.478302 8.37537 -0.0790643 6.64416 0.00905735C4.91294 0.0971789 3.276 0.82452 2.05026 2.05026C0.82452 3.276 0.0971789 4.91294 0.00905735 6.64416C-0.0790643 8.37537 0.478302 10.0777 1.57324 11.4216C2.66819 12.7654 4.22281 13.6552 5.93613 13.9187C7.64945 14.1821 9.39959 13.8005 10.8477 12.8477L16.0017 18.0017L18.0017 16.0017ZM2.00169 7.00169C2.00169 4.24469 4.24469 2.00169 7.00169 2.00169C9.75869 2.00169 12.0017 4.24469 12.0017 7.00169C12.0017 9.75869 9.75869 12.0017 7.00169 12.0017C4.24469 12.0017 2.00169 9.75869 2.00169 7.00169Z" fill="#FFF"/>
</template>

<script>
    export default {
        name: "SearchIcon"
    }
</script>

<style scoped>

</style>