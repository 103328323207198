<template>
    <g>
        <circle cx="13.1582" cy="13" r="12.5" stroke="#383838" fill="#383838"/>
        <circle class="hover" cx="13.1582" cy="13" r="12.5" stroke="#383838" fill="#fff"/>
        <path d="M17.3141 24.5359V15.6531H19.9016L20.2906 12.6484H17.3141V10.7359C17.3141 9.86874 17.5578 9.27811 18.8 9.27811H20.375V6.59686C20.1031 6.55936 19.1609 6.47968 18.0641 6.47968C15.7766 6.47968 14.2109 7.87655 14.2109 10.4406V12.6531H11.6328V15.6578H14.2109V24.9953L17.3141 24.5359Z" fill="#fff"/>
    </g>
</template>

<script>
    export default {
        name: "FacebookIcon"
    }
</script>

<style scoped>

</style>