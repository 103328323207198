<template>
    <router-link
            active-class="is-active"
            to="/">
            <img width="78" height="47" class="logo-img animation-show-move-down"  src="../../assets/logo.svg">
            <img width="109" height="48" class="animation-show-move-down-head-bar" src="../../assets/logoText.svg">
    </router-link>
</template>

<script>
    export default {
        name: "Logo",
        data(){
            return{

            }
        },
        mounted() {

        }

    }
</script>

<style>

</style>